


















































































import { Component } from 'vue-property-decorator'
import { Link } from '@movecloser/ui-core'

import { UnresolvedLink } from '../../../../contracts'

import { FormFieldset } from '../../../atoms'
import { ImageForm, Iterator, LinkForm } from '../../../molecules'

import { AbstractModuleForm } from '../../_abstract'

import { contactPersonContentFactory } from '../ContactPerson.factory'
import { ContactPersonModule, ContactPersonModuleContent } from '../ContactPerson.contracts'

/**
 * TODO: Document all methods and properties.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component({
  name: 'ContactPersonModuleForm',
  components: { FormFieldset, ImageForm, Iterator, LinkForm }
})
export class ContactPersonModuleForm extends AbstractModuleForm<ContactPersonModule> {
  //
  public initialContent: ContactPersonModuleContent
    = contactPersonContentFactory()

  public addBottomLink (): Link | UnresolvedLink {
    return {
      label: '',
      target: ''
    }
  }
}

export default ContactPersonModuleForm
